import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertController, ModalController, NavController, PopoverController } from '@ionic/angular';
import { ColumnMode } from '@swimlane/ngx-datatable';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ComposeComponentOptions, ComposePage } from 'src/app/components/compose/compose.page';
import { OUTBOUND_LEVEL, OUTBOUND_STATUS, routes } from 'src/app/constants';
import { createCommunityRecipient, removeUndefinedFields } from 'src/app/utils/utils';
import { ListQueryResult, Organizer } from 'src/models';
import { AnalyticsService } from 'src/services/analytics.service';
import { ApiService } from 'src/services/api.service';
import { CustomersService } from 'src/services/customers.service';
import { MessageService } from 'src/services/message.service';
import { UsersService } from 'src/services/users.service';

import { TextPopoverComponent } from '../text-popover/text-popover.component';

import { MessageAnalyticsPage } from './message-analytics/message-analytics.page';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss'],
})
export class MessagingComponent implements OnInit, OnDestroy {
  @Input() communityId?: string;
  community: Organizer;
  selected = [];
  messages = [];
  filter = 'all';
  isLoading = false;
  ColumnMode = ColumnMode;
  routerSub: Subscription;
  hasAllSlackScopes = false;
  pendingOutbound: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private apiService: ApiService,
    private msgSrvc: MessageService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private navController: NavController,
    private customersService: CustomersService,
    private usersService: UsersService,
    private analyticsService: AnalyticsService,
  ) {}

  async ngOnInit() {
    const currentPath = this.location.path();

    this.routerSub = this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd && currentPath === e.url))
      .subscribe(() => {
        this.getMessages();

        if (this.communityId) {
          this.getCommunity();
        }
      });

    this.pendingOutbound = this.route.snapshot.queryParamMap.get('outboundId');

    this.getMessages();
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }

  async getCommunity() {
    this.community = (await this.customersService.get(this.communityId)) as Organizer;
  }

  async getMessages() {
    const queryParams: any = {};

    if (this.communityId) {
      queryParams.level = OUTBOUND_LEVEL.COMMUNITY;
      queryParams.communityId = this.communityId;
    }

    if (this.filter !== 'all') {
      queryParams.status = this.filter;
    }

    this.isLoading = true;

    try {
      const result: ListQueryResult = await this.apiService.getPromise(`/outbounds`, queryParams);

      this.messages = result.rows.map((outbound) => {
        if (this.pendingOutbound && this.pendingOutbound === outbound.id) {
          this.viewAnalytics(outbound);
          this.pendingOutbound = undefined;
        }

        return {
          ...outbound,
          sendAt: new Date(outbound.runAt ?? outbound.createdAt),
        };
      });

      // HACK: Fix for issue where message would be marked as "sending" until the page is refreshed. Check if there are any very recently created messages
      // that are "sending" and refresh the messages. Will only attempt for an hour after the message was created so we don't end up in situation where
      // we refresh forever waiting on an actually stuck message.
      if (
        this.messages.some(
          (m) =>
            m.status === OUTBOUND_STATUS.SCHEDULED &&
            !m.runAt &&
            moment(m.createdAt).isAfter(moment().subtract(1, 'hours')),
        )
      ) {
        setTimeout(() => {
          this.getMessages();
        }, 1000);
      }
    } catch (err) {
      this.msgSrvc.showError(err);
    } finally {
      this.isLoading = false;
    }
  }

  async getUsersForTenant() {
    const res = await this.usersService.list();

    return res.data;
  }

  async newMessage() {
    const initialRecipients = [];

    if (this.community) {
      initialRecipients.push(createCommunityRecipient(this.community));
    }

    const modal = await this.modalCtrl.create({
      component: ComposePage,
      componentProps: {
        emails: initialRecipients,
        showTemplates: true,
        callback: () => {
          this.getMessages();
        },
      },
      backdropDismiss: false,
      cssClass: 'modal-large',
    });
    modal.present();
  }

  async editNotes(outbound: any) {
    const popover = await this.popoverCtrl.create({
      component: TextPopoverComponent,
      componentProps: {
        text: outbound.notes,
        shouldSelect: true,
        isMultiline: true,
        callback: async (text: string) => {
          this.isLoading = true;

          const request = this.apiService.put(`/outbounds/${outbound.id}`, {
            ...outbound,
            notes: text,
          });

          request.subscribe(
            async (_result) => {
              this.isLoading = false;
              outbound.notes = text;
            },
            async (err) => {
              this.msgSrvc.show(err.message || "That didn't work, please try again.");
              this.isLoading = false;
            },
          );
        },
      },
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  async viewAnalytics(outbound: any) {
    this.analyticsService.trackEvent('Messaging', 'View outbound analytics', {
      communityId: this.communityId,
      outboundId: outbound.id,
      deliveryMethod: outbound.deliveryMethod,
    });

    const modal = await this.modalCtrl.create({
      component: MessageAnalyticsPage,
      componentProps: {
        outbound,
        community: this.community,
      },
      cssClass: 'modal-large',
    });

    modal.present();
  }

  async editMessage(outbound: any) {
    if (outbound.deliveryMethod === 'email') {
      this.editEmailMessage(outbound);
    } else {
      this.editSlackMessage(outbound);
    }
  }

  async editEmailMessage(outbound: any) {
    const options: ComposeComponentOptions = {
      title:
        outbound.status === OUTBOUND_STATUS.DRAFT
          ? 'Compose email'
          : outbound.status === OUTBOUND_STATUS.SCHEDULED
            ? 'Edit scheduled email'
            : 'Review sent email',
      hideEmails: false,
    };

    const modal = await this.modalCtrl.create({
      component: ComposePage,
      componentProps: {
        communityId: this.communityId,
        showTemplates: outbound.status === 'draft',
        options,
        draft: outbound,
        callback: () => {
          this.getMessages();
        },
      },
      backdropDismiss: false,
      cssClass: 'modal-large',
    });

    modal.present();
  }

  async newSlackMessage() {
    this.navController.navigateForward(`${routes.SLACK_MESSAGE}/new`, {
      queryParams: removeUndefinedFields({
        previousUrl: this.location.path(),
        customerIds: [this.communityId],
      }),
    });
  }

  async editSlackMessage(outbound: any) {
    this.navController.navigateForward(`${routes.SLACK_MESSAGE}/${outbound.id}`, {
      queryParams: {
        previousUrl: this.location.path(),
      },
    });
  }

  viewTemplates() {
    this.navController.navigateRoot(`${routes.DASHBOARD}/${routes.OUTBOUND}/templates`);
  }

  async showError(outbound: any) {
    if (!outbound.error) {
      return;
    }

    const name = `Failed to send outbound`;
    const note = `"${outbound.error}"`;
    const alert = await this.alertCtrl.create({
      header: name,
      subHeader: note,
    });

    alert.present();
  }
}
